const TigerBayPaymentFactory = () => {
    const depositOptionSector = "#pay-option-0";
    const balanceOptionSelector = "#pay-option-1";
    const formSelector = "#paymentForm";
    const amountSelector = "#amount";

    const bindEvents = () => {
        const $form = $(formSelector);
        $form.on('click', depositOptionSector, () => {
            $(balanceOptionSelector).prop("checked", false);
        });
        $form.on('click', balanceOptionSelector, () => {
            $(depositOptionSector).prop("checked", false);
        })
        $form.on('click', 'button', (e) => {
            if (!validateForm()) {
                e.preventDefault();
                return false;
            }

            return true;
        })
    }

    const validateForm = () => {
        var $amount = $(amountSelector);
        var amount = $amount.val();
        console.log(parseFloat(amount));
        if (!parseFloat(amount)) {
            $amount.next('.error').show();
            console.log('ERROR');
            return false;
        }

        return true;
    }

    bindEvents();
}

export default TigerBayPaymentFactory;
